<template>
  <div class="fieldset-wrapper">
    <validation-observer ref="form" :vid="type" tag="div" class="fieldset-wrapper__form">
      <slot />
    </validation-observer>
    <v-button v-if="mediaQueries.mobile" primary class="fieldset-wrapper__save" @click="saveChanges">
      Сохранить
    </v-button>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { SAVE_SECTION_INFO } from '@/store/common/actions.types'
import VButton from '@/components/common/VButton.vue'

export default {
  name: 'VEditFieldsetWrapper',
  components: { ValidationObserver, VButton },
  props: {
    model: { type: Object, required: true },
    module: { type: String, required: true },
    type: { type: String, required: true }
  },
  inject: ['mediaQueries'],
  computed: {
    id() {
      return this.$store.state[this.module].id
    }
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => this.updateStore())
      }
    }
  },
  mounted() {
    // validate fields on initialization if edit (not create)
    if (this.id) {
      this.updateStore()
    }
  },
  methods: {
    updateStore() {
      return this.$refs.form.validate().then(() => {
        this.$store.dispatch(`${this.module}/${SAVE_SECTION_INFO}`, { type: this.type, sectionInfo: this.model })
      })
    },
    saveChanges() {
      this.updateStore().then(() => this.$router.back())
    }
  }
}
</script>
